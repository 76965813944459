import { cloneObject } from 'utils';
import notification from 'utils/noty';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const namespaced = true;

const getDocumentWidth = () => document.documentElement.getBoundingClientRect().width;

const state = {
  formSecurityToken: payload.generic.formSecurityToken,
  overlayStack: [],
  restrictions: payload.generic.restrictions ? cloneObject(payload.generic.restrictions) : [],
  platform: payload.generic.platform ? cloneObject(payload.generic.platform) : null,
  selectedVideo: null,
  accessCodeModalVisible: payload.generic.accessCodeModalVisible ? payload.generic.accessCodeModalVisible : false,
  accessCodeModalMessage: payload.generic.accessCodeModalMessage ? cloneObject(payload.generic.accessCodeModalMessage) : null,
  locales: cloneObject(payload.generic.locales),
  activeDropDownFilter: null,
  page: payload.generic.page,
  mobileMenuVisible: false,
  isScrolled: false,
  theme: cloneObject(payload.generic.theme),
  documentWidth: getDocumentWidth()
};

window.addEventListener('resize', () => {
  state.documentWidth = getDocumentWidth();
}, false);

if (payload.generic.message) {
  notification.showByType(payload.generic.message.content, payload.generic.message.type, { timeout: 0 });
}

document.addEventListener('scroll', () => {
  if (window.scrollY > 32 && !state.isScrolled) {
    state.isScrolled = true;
  }

  if (window.scrollY <= 32 && state.isScrolled) {
    state.isScrolled = false;
  }
});

try {
  let accessCodeModalMessage = sessionStorage.getItem('t1-accessCodeModalMessage');
  if (accessCodeModalMessage) {
    accessCodeModalMessage = JSON.parse(accessCodeModalMessage);
    sessionStorage.removeItem('t1-accessCodeModalMessage');
    state.accessCodeModalVisible = true;
    state.accessCodeModalMessage = accessCodeModalMessage;
  }
} catch (e) {
  // noop
}

export {
  getters, actions, mutations, state
};
